@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap');
html, body {
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #ccc;
  }
}
a:link, a:active, a:visited, a:hover {
  text-decoration: none;
  color: #fff;
}
* {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans KR', sans-serif;
}
.headerContainer {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 4;
}
//home
.container {
  // background-color: white;
  scroll-behavior: smooth;
  width: 100%;
  display: flex;
  flex-direction: column;
  scroll-snap-type: y mandatory;
  & > div{
    scroll-snap-align: center;
  }
}
.nav {
  background-color: #fff;
  color: #212121;
  padding: 25px 5px;
  top: 0;
  display: flex;
  justify-content: space-around;
  &  :is(ul, li) {
    display: inline-block;
  }
  & li {
    padding: 10px 30px 0px 30px;
    // margin: 0 30px;
    font-weight: 600;
    font-size: 1.3rem;
    color: #212121;
  }
  & li:hover {
    cursor: pointer;
    color: rgb(223, 223, 223);
  }
  button {
    color: black;
    border: none;
    background-color: white;
    font-size: 1.5em;
    padding: 8px 6px;
    padding-left: 10px;
    cursor: pointer;
  }
  h2 {
    padding-top: 7px;
    display: inline-block;
    color: #212121;
  }
}
//footer
.footer {
  text-align: center;
  display: block;
  width: 100%;
}

//home
.UseongMap {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  position: relative;
  padding: 28px 0;
  height: fit-content;
  & > img {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.9;
    background-size: cover;
  }
  svg {
    z-index: 2;
    max-height: 500px;
    height: 80%;
  }
  .infoMap {
    z-index: 2;
    margin: 0 40px;
    min-height: 500px;
    min-width: 300px;
    background-color: white;
    border-radius: 15px;
    opacity: 0.9;
    li {
      padding: 3px 20px;
    }
    ul {
      text-align: left;
      list-style: none;
      margin: 5px 0;
      overflow-y: auto;
      height: 450px;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: #ccc;
      }
    }
    h2 {
      background-color: #212121;
      color: white;
      padding: 5px 15px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }
}
.tourCon {
  & > div:nth-child(even) {
    background-color: rgb(238, 238, 238);
    
  }
  & > * {
    width: 50%;
    margin: 0 auto;
    padding: 15px;
    border-radius: 5px;
  }
}

.eventList {
  margin: 10px 0;
  width: fit-content;
  & > * {
    display: inline-block;
    vertical-align: top;
  }
  & > div > * {
    margin: 15px 10px;
  }
  & > div {
    h2 {
      color: #212121;
      font-size: 2.1em;
    }
    p {
      color: rgb(82, 82, 82);
      font-size: 1.2em;
    }
  }
  .eventImg {
    width: 1400px;
  }
}

.festival {
  display: block;
  width: 60%;
  min-width: 800px;
  margin: 0 auto;
  padding: 5px 0;
  & > div > h2 {
    padding: 44px 0;
    font-size: 2.3em;
    font-weight: 900;
    text-align: center;
  }
}
.bannerCon {
  position: fixed;
  width: 100%;
  z-index: -1;
  color: white;
  height: 700px;
  & > div {
    position: relative;
    top: 20%;
    left: 10%;
    text-shadow: 3px 3px 4px #909090;
    h2 {
      font-size: 3.3em;
    }
    p {
      font-size: 1em;
    }
  }
  & > img {
    width: 100%;
    position: absolute;
    top: -12px;
    z-index: -1;
  }
  margin: 10px 0;
}
.homeSection {
  padding: 40px 0;
  background-color: white;
  text-align: center;
  h1 {
    padding: 44px 0;
    font-size: 2.3em;
    font-weight: 900;
  }
  & > div:nth-child(even) {
    background-color: rgb(248, 248, 248);
  }
  .homeEvent {
    display: flex;
    justify-content: space-evenly;
    & > div > img {
      width: 300px;
      height: 180px;
      border-radius: 8px;
    }
    & > div > div {
      display: flex;
      flex-direction: column;
      justify-content: baseline;
      text-align: left;
    }
    padding-bottom: 20px;
  }
}
.ranking {
  & > div {
    & > * {
      display: inline-block;
      vertical-align: top;
      background-color: rgb(248, 248, 248);
    }
    div {
      position: relative;
      min-width: 400px;
      height: 268.72px;
      h2 {
        color: rgb(82, 82, 82);
        padding: 15px;
        text-align: left;
      }
      p {
        font-size: 1.6em;
        font-weight: bold;
        color: rgb(82, 82, 82);
        opacity: 0.8;
        position: absolute;
        top: 48%;
        left: 23%;
      }
      .rank {
        opacity: 0.1;
        font-family: 'Titan One', cursive;
        font-size: 7em;
        position: absolute;
        top: 30%;
        left: 30%;
        color: black;
      }
    }
    
    width: 70%;
    display: block;
    margin: 5% auto;
    &:first-child {
      margin-top: 0;
    }
  }
  & > div:nth-child(even) {
    div {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }
    img {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  & > div:nth-child(odd) {
    img {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }
    div {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
.festivalList {
  display: flex;
  justify-content: space-between;
  & img {
    height: 180px;
    width: 100%;
  }
  .eventListCard {
    border: 1px solid rgb(223, 223, 223);
    border-radius: 8px;
    div {
      padding: 5px 10px;
    }
  }
}

.tourList {
  list-style: none;
  margin: 20px 0;
  padding: 5px 10px;
  & > div {
    display: flex;
    justify-content: space-evenly;
  }
  img {
    display: inline-block;
    width: auto;
    height: 240px;
    border-radius: 8px;
  }
  & > div > img:only-child {
    width: 70%;
    height: auto;
  }
}
.qnaEditContainer {
  display: block;
  margin: 0 auto;
  width: 60%;
  text-align: center;
  input {
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: large;
    font-weight: 500;
    margin: 10px 0;
  }
  input:focus {
    outline: rgb(70, 70, 70);
  }
  button {
    padding: 5px 25px;
    margin: 10px 0;
    background-color: white;
    border: 1px solid #0084ff;
    border-radius: 3px;
    cursor: pointer;
    color: white;
    background-color: #0084ff;
  }
  button:hover {
    background-color: #59acfa;
  }
  button:active {
    text-decoration: underline;
  }
  .qnaEditor {
    text-align: left;
    div:last-child {
      div {
        min-height: 550px;
      }
    }
  }
}
.goqnaedit {
  float: right;
  position: relative;
  top: 40px;
  right: 45px;
  button {
    padding: 5px 15px;
    background-color: white;
    border: 1px solid #0084ff;
    border-radius: 3px;
    cursor: pointer;
    color: white;
    background-color: #0084ff;
  }
  button:hover {
    background-color: #59acfa;
  }
  button:active {
    text-decoration: underline;
  }
}